import React, { useEffect, useState } from "react";
import TableDisplay from "./TableDisplay";
import * as Yup from "yup";
import { GetApi } from "./Helper/Axios";

export default function Profiles() {
  const title = [
    "id",
    "fullname",
    "gender",
    "age",
    "state",
    "notes",
    "intereststag",
    "language",
    "region",
    "coins",
    "data_list_show_type",
    "package_name",
    "status",
  ];
  const [region, setRegion] = useState();
  const [replyMessage, setReplyMeassage] = useState();
  const [autoMessage, setAutoMeassage] = useState();
  let token = localStorage.getItem("token");
  const formConfig = {
    fields: [
      {
        name: "fullname",
        label: "Fullname",
        type: "text",
      },
      {
        name: "gender",
        label: "Gender",
        type: "select",
        options: [
          { id: 1, name: "Male" },
          { id: 2, name: "Female" },
          { id: 3, name: "Other" },
        ],
      },
      {
        name: "notes",
        label: "Notes",
        type: "text",
      },
      {
        name: "age",
        label: "Age",
        type: "text",
      },
      {
        name: "state",
        label: "State",
        type: "text",
      },

      {
        name: "intereststag",
        label: "Intereststag",
        type: "text",
      },
      {
        name: "region",
        label: "Country",
        type: "regionselect",
        options: region && region,
      },
      {
        name: "data_list_show_type",
        label: "Data List Show Type",
        type: "select",
        options: [
          { id: "Master", name: "Master" },
          { id: "Organic", name: "Organic" },
          { id: "Gc", name: "Gc" },
          { id: "Fc", name: "Fc" },
          { id: "3rd Party", name: "3rd Party" },
        ],
      },
      {
        name: "coins",
        label: "Coins",
        type: "text",
      },
      {
        name: "language",
        label: "Language",
        type: "selectlang",
        options: [
          { value: "English", label: "English" },
          { value: "Mandarin", label: "Mandarin" },
          { value: "Hindi", label: "Hindi" },
          { value: "Spanish", label: "Spanish" },
          { value: "Filipino", label: "Filipino" },
          { value: "French", label: "French" },
          { value: "Gujarati", label: "Gujarati" },
          { value: "Arabic", label: "Arabic" },
          { value: "Bengali", label: "Bengali" },
          { value: "Russian", label: "Russian" },
          { value: "Portuguese", label: "Portuguese" },
          { value: "Urdu", label: "Urdu" },
          { value: "Indonesian", label: "Indonesian" },
          { value: "German", label: "German" },
          { value: "Japanese", label: "Japanese" },
          { value: "Nigerian", label: "Nigerian" },
          { value: "Marathi", label: "Marathi" },
          { value: "Telugu", label: "Telugu" },
          { value: "Turkish", label: "Turkish" },
          { value: "Tamil", label: "Tamil" },
          { value: "Vietnamese", label: "Vietnamese" },
          { value: "YueChinese", label: "Yue Chinese" },
        ],
      },
      {
        name: "reply_message_id",
        label: "Reply Message Id",
        type: "replyselect",
      },
      {
        name: "auto_message_id",
        label: "Auto Message Id",
        type: "autoselect",
      },
      {
        name: "package_name",
        label: "Package Name",
        type: "text",
      },
      {
        name: "images",
        label: "Image",
        type: "files",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      fullname: Yup.string().required("fullname Required"),
      coins: Yup.number().required("coins Required"),
      gender: Yup.string().required("select gender"),
      age: Yup.number().required("age Required in number"),
      state: Yup.string().required("state Required"),
      notes: Yup.string().required("notes Required"),
      intereststag: Yup.string().required("intereststag Required"),
      region: Yup.string().required("region Required"),
      data_list_show_type: Yup.string().required("select data_list_show_type"),
    }),
  };
  const initialValues = {
    fullname: "",
    gender: "",
    age: "",
    state: "",
    notes: "",
    intereststag: "",
    language: "",
    data_list_show_type: "",
    reply_message_id: "",
    auto_message_id: "",
    status: 0,
    region: "",
    images: "",
    coins: "",
    package_name: "",
  };
  const fetchData = async () => {
    await GetApi("admin-get-country", token)
      .then((res) => setRegion(res.data.data.paginatedData))
      .catch((err) => console.log("err", err));
    await GetApi("admin-get-reply-message-profile", token)
      .then((res) => {
        setReplyMeassage(res.data.data.paginatedData);
      })
      .catch((err) => console.log("err", err));
    await GetApi("admin-get-auto-message-profile", token)
      .then((res) => setAutoMeassage(res.data.data.paginatedData))
      .catch((err) => console.log("err", err));
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <TableDisplay
        category="Profile"
        tableurl="admin-get-all-profile"
        title={title}
        deleteurl="admin-delete-profile"
        deleteallurl="admin-all-delete-profile"
        addurl="add-profile"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="admin-get-edit-profile"
        updatteurl="admin-edit-profile"
        serachCategory=""
        statusurl="admin-status-profile"
        axiosHeader={true}
        replyMessage={replyMessage}
        autoMessage={autoMessage}
      />
    </div>
  );
}
