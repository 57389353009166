import { useState } from "react";

export default function Pagination({
  currentPage,
  totalPages,
  onNext,
  onPrev,
  handlePageChange,
  handleCoinDescAsc
}) {
  // const [currentPage, setCurrentPage] = useState(1);
  const maxPagesToShow = 5; // Number of page numbers to show
  // const paginate = (pageNumber) => {
  //     setCurrentPage(pageNumber);
  // };
  const getPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= maxPagesToShow) {
      // If total pages is less than or equal to maxPagesToShow, show all pages
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // If total pages is greater than maxPagesToShow, show subset with ellipsis
      const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
      let startPage = currentPage - halfMaxPagesToShow;
      let endPage = currentPage + halfMaxPagesToShow;

      if (startPage <= 0) {
        startPage = 1;
        endPage = maxPagesToShow;
      }

      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = totalPages - maxPagesToShow + 1;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (startPage > 1) {
        pageNumbers.unshift("...");
      }
      if (endPage < totalPages) {
        pageNumbers.push("...");
      }
    }

    return pageNumbers;
  };

  return (
    <div>
      {/* Pagination controls */}
      <div className="d-flex justify-content-center">
        {/* Previous button */}
        <button className="pagination__next leftarw" onClick={() => {handlePageChange(1); handleCoinDescAsc(false)}} disabled={currentPage === 1}>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 17.4164C10.8179 17.4175 10.6397 17.3643 10.4879 17.2637C10.3362 17.163 10.2179 17.0195 10.1481 16.8513C10.0783 16.6832 10.0601 16.498 10.0959 16.3195C10.1317 16.141 10.2199 15.9772 10.3492 15.8489L15.2075 10.9998L10.3492 6.15061C10.199 5.97525 10.1205 5.74968 10.1294 5.51898C10.1383 5.28828 10.234 5.06943 10.3972 4.90618C10.5605 4.74293 10.7793 4.64729 11.01 4.63838C11.2407 4.62947 11.4663 4.70794 11.6417 4.85811L17.1417 10.3581C17.3124 10.5299 17.4082 10.7622 17.4082 11.0044C17.4082 11.2465 17.3124 11.4789 17.1417 11.6506L11.6417 17.1506C11.4709 17.32 11.2405 17.4154 11 17.4164Z" fill="black"/>
            <path d="M5.5 17.4162C5.31793 17.4172 5.13966 17.3641 4.98794 17.2634C4.83621 17.1628 4.7179 17.0192 4.64808 16.851C4.57826 16.6829 4.5601 16.4977 4.59591 16.3192C4.63173 16.1407 4.71989 15.9769 4.84917 15.8487L9.7075 10.9995L4.84917 6.15035C4.67656 5.97774 4.57958 5.74363 4.57958 5.49952C4.57958 5.25541 4.67656 5.0213 4.84917 4.84869C5.02178 4.67607 5.25589 4.5791 5.5 4.5791C5.74411 4.5791 5.97822 4.67607 6.15083 4.84869L11.6508 10.3487C11.8216 10.5204 11.9174 10.7528 11.9174 10.9949C11.9174 11.2371 11.8216 11.4694 11.6508 11.6412L6.15083 17.1412C6.06594 17.2278 5.9647 17.2967 5.85298 17.3439C5.74126 17.3911 5.62128 17.4157 5.5 17.4162Z" fill="black"/>
          </svg>
        </button>
        <button className="pagination__next leftarw" onClick={() => {onPrev(currentPage - 1); handleCoinDescAsc(false)}} disabled={currentPage === 1}>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.922 17.4162C7.73993 17.4172 7.56166 17.3641 7.40994 17.2634C7.25821 17.1628 7.13989 17.0192 7.07007 16.851C7.00025 16.6829 6.98209 16.4977 7.01791 16.3192C7.05372 16.1407 7.14189 15.9769 7.27116 15.8487L12.1295 10.9995L7.27116 6.15035C7.09855 5.97774 7.00158 5.74363 7.00158 5.49952C7.00158 5.25541 7.09855 5.0213 7.27116 4.84869C7.44378 4.67607 7.67789 4.5791 7.922 4.5791C8.16611 4.5791 8.40022 4.67607 8.57283 4.84869L14.0728 10.3487C14.2436 10.5204 14.3394 10.7528 14.3394 10.9949C14.3394 11.2371 14.2436 11.4694 14.0728 11.6412L8.57283 17.1412C8.48793 17.2278 8.38669 17.2967 8.27497 17.3439C8.16326 17.3911 8.04328 17.4157 7.922 17.4162Z" fill="black"/>
          </svg>
        </button>

        {/* Page numbers */}
        {getPageNumbers().map((pageNumber, index) => (
          <button className={`pagination__item ${currentPage === pageNumber ? "active" : ""}`} key={index}
            onClick={() => {
              if (pageNumber !== "...") {
                handlePageChange(pageNumber);
              }
              handleCoinDescAsc(false)
            }}
            disabled={pageNumber === "..."}
          >
            {pageNumber}
          </button>
        ))}

        {/* Next button */}
        <button className="pagination__next" onClick={() => {onNext(currentPage + 1); handleCoinDescAsc(false)}} disabled={currentPage === totalPages}>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.922 17.4162C7.73993 17.4172 7.56166 17.3641 7.40994 17.2634C7.25821 17.1628 7.13989 17.0192 7.07007 16.851C7.00025 16.6829 6.98209 16.4977 7.01791 16.3192C7.05372 16.1407 7.14189 15.9769 7.27116 15.8487L12.1295 10.9995L7.27116 6.15035C7.09855 5.97774 7.00158 5.74363 7.00158 5.49952C7.00158 5.25541 7.09855 5.0213 7.27116 4.84869C7.44378 4.67607 7.67789 4.5791 7.922 4.5791C8.16611 4.5791 8.40022 4.67607 8.57283 4.84869L14.0728 10.3487C14.2436 10.5204 14.3394 10.7528 14.3394 10.9949C14.3394 11.2371 14.2436 11.4694 14.0728 11.6412L8.57283 17.1412C8.48793 17.2278 8.38669 17.2967 8.27497 17.3439C8.16326 17.3911 8.04328 17.4157 7.922 17.4162Z" fill="black"/>
          </svg>
        </button>
        <button className="pagination__next" onClick={() => {handlePageChange(totalPages); handleCoinDescAsc(false)}} disabled={currentPage === totalPages}>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 17.4164C10.8179 17.4175 10.6397 17.3643 10.4879 17.2637C10.3362 17.163 10.2179 17.0195 10.1481 16.8513C10.0783 16.6832 10.0601 16.498 10.0959 16.3195C10.1317 16.141 10.2199 15.9772 10.3492 15.8489L15.2075 10.9998L10.3492 6.15061C10.199 5.97525 10.1205 5.74968 10.1294 5.51898C10.1383 5.28828 10.234 5.06943 10.3972 4.90618C10.5605 4.74293 10.7793 4.64729 11.01 4.63838C11.2407 4.62947 11.4663 4.70794 11.6417 4.85811L17.1417 10.3581C17.3124 10.5299 17.4082 10.7622 17.4082 11.0044C17.4082 11.2465 17.3124 11.4789 17.1417 11.6506L11.6417 17.1506C11.4709 17.32 11.2405 17.4154 11 17.4164Z" fill="black"/>
            <path d="M5.5 17.4162C5.31793 17.4172 5.13966 17.3641 4.98794 17.2634C4.83621 17.1628 4.7179 17.0192 4.64808 16.851C4.57826 16.6829 4.5601 16.4977 4.59591 16.3192C4.63173 16.1407 4.71989 15.9769 4.84917 15.8487L9.7075 10.9995L4.84917 6.15035C4.67656 5.97774 4.57958 5.74363 4.57958 5.49952C4.57958 5.25541 4.67656 5.0213 4.84917 4.84869C5.02178 4.67607 5.25589 4.5791 5.5 4.5791C5.74411 4.5791 5.97822 4.67607 6.15083 4.84869L11.6508 10.3487C11.8216 10.5204 11.9174 10.7528 11.9174 10.9949C11.9174 11.2371 11.8216 11.4694 11.6508 11.6412L6.15083 17.1412C6.06594 17.2278 5.9647 17.2967 5.85298 17.3439C5.74126 17.3911 5.62128 17.4157 5.5 17.4162Z" fill="black"/>
          </svg>
        </button>
      </div>
    </div>

    // <div className="d-flex justify-content-center">
    //     <button className="pagination__item" onClick={onPrev} disabled={currentPage === 1}>
    //         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //             <path d="M14.9998 20.67C14.8098 20.67 14.6198 20.6 14.4698 20.45L7.94979 13.93C6.88979 12.87 6.88979 11.13 7.94979 10.07L14.4698 3.55002C14.7598 3.26002 15.2398 3.26002 15.5298 3.55002C15.8198 3.84002 15.8198 4.32002 15.5298 4.61002L9.00979 11.13C8.52979 11.61 8.52979 12.39 9.00979 12.87L15.5298 19.39C15.8198 19.68 15.8198 20.16 15.5298 20.45C15.3798 20.59 15.1898 20.67 14.9998 20.67Z" fill="#292D32" />
    //         </svg>

    //     </button>
    //     {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
    //         <button className={`pagination__item ${(currentPage === (page) ? "active" : '')}`} key={page} onClick={() => handlePageChange(page)}>
    //             {page}
    //         </button>
    //     ))}
    //     <button className="pagination__next" onClick={onNext} disabled={currentPage === totalPages}>
    //         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //             <path d="M8.9101 20.67C8.7201 20.67 8.5301 20.6 8.3801 20.45C8.0901 20.16 8.0901 19.68 8.3801 19.39L14.9001 12.87C15.3801 12.39 15.3801 11.61 14.9001 11.13L8.3801 4.61002C8.0901 4.32002 8.0901 3.84002 8.3801 3.55002C8.6701 3.26002 9.1501 3.26002 9.4401 3.55002L15.9601 10.07C16.4701 10.58 16.7601 11.27 16.7601 12C16.7601 12.73 16.4801 13.42 15.9601 13.93L9.4401 20.45C9.2901 20.59 9.1001 20.67 8.9101 20.67Z" fill="#292D32" />
    //         </svg>
    //     </button>
    // </div>

  );
}
