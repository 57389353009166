import React, { useState } from "react";
import TableDisplay from "./TableDisplay";
import * as Yup from "yup";
export default function FakeVideo() {
  const [title, setTitle] = useState(["id", "video", "status"]);
  const formConfig = {
    fields: [
      {
        name: "video",
        label: "Video",
        type: "video",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      video: Yup.mixed().required("Please upload an image."),
    }),
  };

  const initialValues = {
    video: "",
    status: 0,
  };
  return (
    <div>
      <TableDisplay
        category="Fake Video Data"
        tableurl="admin-get-fakevideo"
        title={title}
        deleteurl="admin-delete-fakevideo"
        deleteallurl="admin-all-delete-fakevideo"
        addurl="add-fakevideo"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-edit-fakevideo"
        updatteurl="admin-edit-fakevideo"
        statusurl="admin-status-fakevideo"
        axiosHeader={true}
      />
    </div>
  );
}
