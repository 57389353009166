import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { GetApi, PostApi } from "./Helper/Axios";
import Pagination from "./Pagination";
import Popup from "./Popup";
import MyContext from "./MyContext";
import { useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Highlighter from "react-highlight-words";

export default function TableDisplay({
  tableurl, title, deleteurl, deleteallurl,
  category, formConfig, initialValues, addurl, 
  updateurl, updatteurl, serachCategory, statusurl,
  axiosHeader, replyMessage, autoMessage 
}) {
  let token = localStorage.getItem("token");
  let permission_module;
  const location = useLocation();
  const urlName = location.pathname;
  let module = urlName.replace(/^\/|\/$/g, "");

  const user = useSelector((state) => state.auth.user);
  const isSuperAdmin = user && user.is_superadmin;
  const permission = user && JSON.parse(user?.permission);

  if (permission !== null) {
    permission_module = { ...permission[module] };
  }

  if (isSuperAdmin === 1) {
    permission_module = {
      read: true,
      create: true,
      update: true,
      delete: true,
    };
  }

  const successNotify = (message) =>
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const errorNotify = (message) =>
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const [data, setData] = useState();
  const [success, setSuccess] = useState("");
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [updatedata, setUpdateData] = useState({});
  const [isupdate, setIsupdate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteid, setdeleteid] = useState(0);
  const handleDeleteClose = () => setShowDelete(false);
  const handleDeleteShow = () => setShowDelete(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [error, setError] = useState("");
  const [isChecked, setIsChecked] = useState(selectedItems?.length === data?.length ? true : false);
  const [country, setCountry] = useState();
  const [countrytype, setCountryType] = useState();
  const [count, setCount] = useState(0);

  /********************** */
  // const [formData, setFormData] = useState();
  const [shows, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /****************************** */
  /************* Pagination **************/
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [coinDescAsc, setCoinDescAsc] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setSelectedItems([]);
    setCurrentPage(pageNumber);
  };
  
  const handleCoinDescAsc = (descAsc) => {
    setCoinDescAsc(descAsc);
  };

  useEffect(() => {
    let timer;
    if (error) {
      timer = setTimeout(() => {
        setError("");
      }, 3000); // 3000 milliseconds = 3 seconds
    }
    if (success) {
      timer = setTimeout(() => {
        setSuccess("");
      }, 3000); // 3000 milliseconds = 3 seconds
    }
    return () => {
      clearTimeout(timer);
    };
  }, [error, success]);

  /***************** */
  // (search !== null && type !== undefined) || type !== null;
  const [type, setType] = useState(null);
  const [genderType, setgenderType] = useState("");

  const fetchData = async () => {
    // let url =
    //   (search !== null && type !== undefined) || type !== null
    //     ? `${tableurl}?page=${currentPage}&search=${search}&datatype=${type}&gendertype=${genderType}`
    //     : search !== null
    //     ? `${tableurl}?page=${currentPage}&search=${search}`
    //     : type !== null
    //     ? `${tableurl}?page=${currentPage}&datatype=${type}`
    //     : `${tableurl}?page=${currentPage}`;
    // let url = tableurl
    setPageLoader(true);
    const url = `${tableurl}?page=${currentPage}` +
      (coinDescAsc ? `&coindescasc=${coinDescAsc}`: "") +
      (search ? `&search=${search}` : "") +
      (type ? `&datatype=${type}` : "") +
      (countrytype ? `&country=${countrytype}` : "") +
      (genderType ? `&gendertype=${genderType}` : "");
    await GetApi(url, token)
      .then(({ data }) => {
        setData(data.data.paginatedData);
        // setData(data.data);
        setTotalPages(data.data.totalPages);
        setCount(data.data.count);
        setPageLoader(false);
      })
      .catch((err) => {
        console.log("Fetching data errror", err);
        if (err.response.status === 403) {
          setError(err.response.data.message);
        }
        setPageLoader(false);
      });
    await GetApi("admin-get-country", token)
      .then((res) => {
        setCountry(res.data.data.paginatedData);
      })
      .catch((err) => console.log("err", err));
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, type, search, selectedItems, genderType, countrytype, coinDescAsc]);

  const handleDelete = async (id) => {
    await GetApi(`${deleteurl}/${id}`, token)
      .then((res) => {
        if (res.status === 200) {
          fetchData();
          successNotify(res.data.message);
          handleDeleteClose();
          // fetchData();
          if (data.length === 0) {
            setCurrentPage(currentPage - 1);
          }
          setCurrentPage(currentPage);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteAll = async () => {
    await GetApi(`${deleteallurl}/${selectedItems}`, token)
      .then((res) => {
        successNotify(res.data.message);
        handleDeleteClose();
        setSelectedItems([]);
        fetchData();
        if (res.status === 200) {
          setIsChecked(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const statuscheckboxchange = (e) => {
    let i = [];
    if (e === true) {
      setIsChecked(true);
      i = data.map((item) => item.id);
    }
    if (e === false) {
      setIsChecked(false);
      i = [];
    }
    setSelectedItems(i);
  };

  const handleUpdate = async (id) => {
    await GetApi(`${updateurl}/${id}`, token)
      .then((res) => {
        setUpdateData(res.data.data);
        setIsupdate(true);
        handleShow();
      })
      .catch((err) => console.log(err));
  };

  let options = replyMessage?.map((country) => ({
    value: country?.id?.toString(), // Convert ID to string
    label: country.meassage, // Use country name as label
  }));

  let lan = updatedata.language && updatedata.language.split(",");

  const convertedArray = lan && lan.map((item) => ({
    value: item,
    label: item,
  }));

  const replyi = updatedata.reply_message_id && updatedata?.reply_message_id?.split(",");

  let filteredData = [];
  const test = replyi?.map((val) => {
    let data = options?.find((vals) => vals.value === val);
    filteredData.push(data);
  });

  const autoOpt = autoMessage && autoMessage.map((country) => ({
    value: country?.id?.toString(), // Convert ID to string
    label: country.meassage, // Use country name as label
  }));

  const replyauto = updatedata.auto_message_id && updatedata?.auto_message_id?.split(",");
  const filteredDataauto = [];
  const test1 = replyauto?.map((val) => {
    let data = autoOpt?.find((vals) => vals.value === val);
    filteredDataauto.push(data);
  });

  autoOpt && autoOpt.filter((item) => replyauto?.includes(item.value));
  const value = {
    title: category,
    isupdate: isupdate,
    handleClose: handleClose,
    isShow: shows,
    formConfig: formConfig,
    url: addurl,
    data: isupdate ? updatedata : initialValues,
    filteredData: isupdate ? filteredData : [],
    filteredDataauto: isupdate ? filteredDataauto : [],
    autoOption: autoOpt,
    options: options,
    fetchData: fetchData,
    updatteurl: updatteurl,
    axiosHeader: axiosHeader,
    token: token,
    replyMessage: replyMessage,
    autoMessage: autoMessage,
    languageoptions: isupdate ? convertedArray : [],
  };

  const handleStatusChange = async (statuss, id) => {
    const newdata = {
      status: statuss,
    };    
    await PostApi(`${statusurl}/${id}`, newdata, false, token)
      .then((res) => {
        if (res.status === 200) {
          successNotify(res.data.message);
          fetchData();
        }
      })
      .catch((err) => console.log(err));
  };

  const toggleSelectItem = (itemId) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(itemId)) {
        return prevSelected.filter((id) => id !== itemId);
      } else {
        return [...prevSelected, itemId];
      }
    });
    if (data?.length === data?.length) {
      setIsChecked(true);
    }
  };

  const highlightText = (text, search) => (
    <Highlighter
      highlightClassName="YourHighlightClass"
      searchWords={[search]}
      autoEscape={true}
      textToHighlight={text !== null && text !== undefined ? text.toString() : text}
    />
  );

  function convertUTCToLocalTime(utcTimestamp) {
    const date = new Date(utcTimestamp);

    // Format options for readable date and time without seconds and timezone
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };

    // Convert to local string
    const localString = date.toLocaleString('en-US', options);

    return `${localString.replace(',', '')}`;
  }

  return (
    <MyContext.Provider value={value}>
      {error ? (
        <div className="alert-danger text-center alert">
          <p>{error}</p>
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-between userr">
            <div className="title w-60 d-flex justify-content-between align-items-center user-info">
                <h4 className="d-flex justify-content-between mb-0 marginright">{category}</h4>
                <p className="mb-0">
                  {count > 0 ? <span className="h6 mb-0">{`( ${count} Records )`}</span> : null}
                </p>              
            </div>
            <div className="w-50 d-flex flex-row-reverse user-info flex-wrap">
              {permission_module?.create === true && category !== "Users" && category !== "Payment" ? (
                <button className="mybtn mb-2" onClick={() => { handleShow(); setIsupdate(false); }}>{`add ${category}`}</button>
              ) : null}
              {category !== "Fake Video Data" && category !== "Setting" && category !== "Real Video Data" ? (
                <div className="search marginright">
                  <input type="search" className="search__input marginright popup__input" onChange={(e) => { setSearch(e.target.value) }} placeholder={`Search ${serachCategory}`}/>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="black" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
                  </svg>
                </div>
              ) : null}
                <div className="d-flex gap-4 justify-content-between marginright">
                  {category === "Profile" || category === "Users" ? (
                    <div className="dropdown-wrap d-flex">
                      {/* <label className='popup__label marginright'>Show</label> */}
                      <div className="custon position-relative">
                        <select onChange={(e) => { setgenderType(e.target.value); }} className="my-select mb-0">
                          <option value="">All</option>
                          <option value="1">Male</option>
                          <option value="2">Female</option>
                          <option value="3">Others</option>
                        </select>
                      </div>
                    </div>
                  ) : null}
                  {category === "Profile" ? (
                    <div className="dropdown-wrap d-flex">
                      {/* <label className='popup__label marginright'>Show</label> */}
                      <div className="custon position-relative">
                        <select onChange={(e) => { setType(e.target.value); }} className="my-select">
                          <option value="">All</option>
                          <option value="Master">Master</option>
                          <option value="Organic">Organic</option>
                          <option value="Gc">Gc</option>
                          <option value="Fc">Fc</option>
                          <option value="3rd Party">3rd Party</option>
                        </select>
                      </div>
                    </div>
                  ) : null}
                  {category === "Profile" ? (
                    <div className="dropdown-wrap d-flex">
                      {/* <label className='popup__label marginright'>Show</label> */}
                      <div className="custon position-relative">
                        <select onChange={(e) => { setCountryType(e.target.value); }} className="my-select">
                          <option value="">Select Country</option>
                          {country && country.map((country) => (
                            <option value={country.id}>
                              {country.country}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ) : null}
                </div>
            </div>
          </div>              
          <Table responsive="sm" className={`mytable ${category === "Users" ? "users" : ""} ${category}`}>
            <thead>
              {selectedItems.length === 0 || (isChecked === true && selectedItems.length !== 0) || (isChecked === false && selectedItems.length === data.length) ? (
                <tr>
                  <th>
                    {category !== "Users" && category !== "Payment" && data?.length > 0 && permission_module?.delete === true ? (
                      <div className="checkbox">
                        <input type="checkbox" className="checkbox__input" checked={isChecked && selectedItems?.length === data?.length} onChange={() => {statuscheckboxchange(!isChecked);}}/>
                        <span className="checkbox__in">
                          <span className="checkbox__tick"></span>
                        </span>
                      </div>
                    ) : null}
                  </th>
                  {selectedItems?.length > 0 ? (
                    <th colSpan={title.length}>
                      {selectedItems?.length} Selected
                    </th>
                  ) : (title && title.map((title, index) => (
                      <th key={index} className={title}>
                        {(title === "coins" && category === "Users") ? <p className="m-0 d-flex align-items-center gap-2" style={{cursor: "pointer"}} onClick={(e) => {setCoinDescAsc(!coinDescAsc); setCurrentPage(1)}}> coins
                          <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.0727 13H11.733H6.92462C6.1018 13 5.69039 14.2143 6.27322 14.9261L10.713 20.3484C11.4244 21.2172 12.5815 21.2172 13.2929 20.3484L14.9814 18.2862L17.7327 14.9261C18.3069 14.2143 17.8955 13 17.0727 13Z" fill={`${coinDescAsc ? '#6C5DD3' : '#864dff6e'}`}/>
                            <path d="M17.7267 9.07393L14.9743 5.71377L13.2938 3.65162C12.5821 2.78279 11.4246 2.78279 10.713 3.65162L6.27151 9.07393C5.68846 9.78574 6.1086 11 6.92315 11H11.7333H17.075C17.8982 11 18.3097 9.78574 17.7267 9.07393Z" fill={`${coinDescAsc? '#6C5DD3' : '#864dff6e'}`}/>
                          </svg>
                        </p> : title === "region" && category === "Profile" ? "country" : title === "createdAt" ? "Create Date" : title}
                      </th>
                    ))
                  )}
                  {isChecked === true && selectedItems.length !== 0 ? (
                    <th>
                      <Dropdown className={`dotdisplay ${isChecked === true && selectedItems.length !== 0 ? "showw" : "" }`}>
                        <Dropdown.Toggle id="dropdown-basic" className="my-dropdown">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                            <path fill="currentcolor" d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"/>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {/* <Dropdown.Item>Action</Dropdown.Item> */}
                          <button className="showWrap auto" type="button" onClick={handleDeleteShow}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                              <path fill="currentcolor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
                            </svg>
                            Delete
                          </button>
                        </Dropdown.Menu>
                      </Dropdown>
                    </th>
                  ) : category !== "Users" && category !== "Payment" && permission_module?.delete === true && permission_module?.update === true ? (
                    <th>Action</th>
                  ) : null}
                </tr>
              ) : null}
            </thead>
            <tbody>
              {!pageLoader ? (
                data && data.length > 0 ? (
                  data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="align-middle">
                        {category !== "Users" && category !== "Payment" && permission_module?.delete === true ? (
                          <div className="checkbox">
                            <input type="checkbox" className="checkbox__input" onChange={() => toggleSelectItem(row.id)} checked={selectedItems.includes(row.id)}/>
                            <span className="checkbox__in">
                              <span className="checkbox__tick"></span>
                            </span>
                          </div>
                        ) : null}
                      </td>
                      {title.map((title, colIndex) => (
                        <td key={colIndex} className={`align-middle ${title} ${(title === "coins" && category === "Users") ? 'text-center' : ''}`}>
                          {title === "gender" ? (
                            row[title] === 1 ? (
                              "Male"
                            ) : row[title] === 2 ? (
                              "Female"
                            ) : row[title] === 3 ? (
                              "Others"
                            ) : null
                          ) : title === "icon" ? (
                            <div className="imageWrap">
                              <img src={`${process.env.REACT_APP_UPLOADS_URL}icons/${row.icon}`} alt="imag"/>
                            </div>
                          ) : title === "region" && category !== "Users" ? (
                            // highlightText(row.countryProfile?.region, search)
                            row.countryProfile?.country
                          ) : title === "user name" ? (
                            // highlightText( row?.userPayemntData?.fullname, search)
                            row?.userPayemntData?.fullname
                          ) : title === "category" ? (
                            // highlightText(row?.CatInterestData?.name, search)
                            row?.CatInterestData?.name
                          ) : title === "Package Name" ? (
                            // highlightText( row?.userPayemntData?.packagename, search)
                            row?.userPayemntData?.packagename
                          ) : title === "createdAt" ? (
                            highlightText(convertUTCToLocalTime(row?.createdAt), search)
                            // convertUTCToLocalTime(row?.createdAt)
                          ) : title === "flag" ? (
                            <div className="imageWrap">
                              <img src={`${process.env.REACT_APP_UPLOADS_URL}flags/${row.flag}`} alt="flag"/>
                            </div>
                          ) : title === "video" ? (
                            <div className="imageWrap video">
                              <video src={`${process.env.REACT_APP_UPLOADS_URL}videos/${row.video}`} alt="video" controls muted={false}/>
                            </div>
                          ) : title === "logo" ? (
                            <div className="imageWrap">
                              <img src={`${process.env.REACT_APP_UPLOADS_URL}logos/${row.logo}`} alt="logo"/>
                            </div>
                          ) : title === "status" || title === "is_active" ? (
                            <label className="switch">
                              <input type="checkbox" checked={row[title] || 0} onChange={() => handleStatusChange( row[title] === 1 ? 0 : 1, row.id)} />
                              <span className="slider round"></span>
                            </label>
                          ) : (
                            // highlightText(row[title], search)
                            row[title]
                          )}
                        </td>
                      ))}
                      {category !== "Users" && category !== "Payment" && selectedItems.length === 0 ? (
                        <td className="align-middle">
                          <div className="d-flex">
                            {permission_module.update === true ? (
                              <button className="showWrap marginright" onClick={() => { handleUpdate(row.id) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                  <path fill="currentcolor" d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/>
                                </svg>
                              </button>
                            ) : null}
                            {permission_module?.delete === true ? (
                              <button className="showWrap" onClick={() => { handleDeleteShow(); setdeleteid(row.id); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                  <path fill="currentcolor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
                                </svg>
                              </button>
                            ) : null}
                          </div>
                        </td>
                      ) : (
                        <td></td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan={title.length + 2}>
                      No records found.
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td className="text-center" colSpan={title.length + 1}>
                    <div className="loaderWrap">
                      <div className="loader-15"></div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>            
          
          {success && success ? (
            <div className="alert alert-success fixed-top rr">{success}</div>
          ) : null}

          {error && visibleAlert ? (
            <div className="alert alert-danger" role="alert">{error}</div>
          ) : null}

          <Modal show={showDelete} onHide={handleDeleteClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this {category}.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" className="mybtn black" onClick={handleClose}>Cancel</Button>
              {selectedItems.length === 0 ? (
                <Button variant="primary" className="mybtn" onClick={() => handleDelete(deleteid)}>Delete</Button>
              ) : (
                <Button variant="primary" className="mybtn" onClick={handleDeleteAll}>Delete</Button>
              )}
            </Modal.Footer>
          </Modal>

          {totalPages > 1 ? (
            <Pagination
              currentPage={currentPage} totalPages={totalPages} handleCoinDescAsc={handleCoinDescAsc}
              onNext={handleNextPage} onPrev={handlePrevPage}
              handlePageChange={handlePageChange}
            />
          ) : null}

          {shows && <Popup />}
        </div>
      )}
    </MyContext.Provider>
  );
}
