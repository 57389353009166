// import { createStore } from 'redux';
// import rootReducer from './reducer';


// const store = createStore(rootReducer);

// export default store;

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
    },
});

export const RootState = store.getState;
export const AppDispatch = store.dispatch;

export default store;