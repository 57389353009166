import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { GetApi } from "../Helper/Axios";
import { loginUser } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import logoImage from "../../images/logo.png";
import { ReactComponent as UserSvg } from "../../images/user.svg";
import { ReactComponent as CountrySvg } from "../../images/location.svg";
import { ReactComponent as CoinSvg } from "../../images/coin.svg";
import { ReactComponent as PaymentsSvg } from "../../images/card.svg";
import { ReactComponent as ProfileSvg } from "../../images/profile.svg";
import { ReactComponent as ReplySvg } from "../../images/reply.svg";
import { ReactComponent as AutoSvg } from "../../images/messages.svg";
import { ReactComponent as SettingSvg } from "../../images/settings.svg";
import { ReactComponent as MoreappsSvg } from "../../images/activity.svg";
import { ReactComponent as FakeVideoSvg } from "../../images/video.svg";
import Modal from "react-bootstrap/Modal";
import Signup from "../Signup";

export default function Sidebar() {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const path = location.pathname;
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetUser();
  }, []);

  const handleGetUser = async () => {
    await GetApi("admin-user", localStorage.getItem("token"))
      .then(({ data }) => {
        dispatch(loginUser(data.data));
      })
      .catch((err) => console.log(err));
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="navbarclass">
        <label htmlFor="menu" className="icon">
          <div className="menu d-flex justify-content-between">
            <div className="logo-wrap">
              <img src={logoImage} alt="logo" />
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              viewBox="0 0 24 24"
            >
              <path d="M22 12H3" stroke="#11142d"></path>
              <g stroke="#808191">
                <path d="M22 4H13"></path>
                <path opacity=".301" d="M22 20H13"></path>
              </g>
              <path d="M7 7l-5 5 5 5" stroke="#11142d"></path>
            </svg>
          </div>
        </label>

        <nav>
          <div className="menu-title">
            <p>Admin tools</p>
          </div>
          <ul className="sidemenu">
            {/* {isUserView ? ( */}
            <li className={`${path}` === "/user" ? "active" : ""}>
              <NavLink aria-current="page" to="/user">
                <div className="svgwarp">
                  <UserSvg />
                </div>
                Users
              </NavLink>
            </li>
            {/* ) : null} */}

            <li className={`${path}` === "/country" ? "active" : ""}>
              <NavLink aria-current="page" to="/country">
                <div className="svgwarp">
                  <CountrySvg />
                </div>
                Country
              </NavLink>
            </li>

            <li className={`${path}` === "/coin" ? "active" : ""}>
              <NavLink aria-current="page" to="/coin">
                <div className="svgwarp">
                  <CoinSvg />
                </div>
                Coin Pack
              </NavLink>
            </li>
            {/* {isPaymentView ? ( */}
            <li className={`${path}` === "/payment" ? "active" : ""}>
              <NavLink aria-current="page" to="/payment">
                <div className="svgwarp">
                  <PaymentsSvg />
                </div>
                Payments
              </NavLink>
            </li>
            {/* ) : null} */}
            <li className={`${path}` === "/profile" ? "active" : ""}>
              <NavLink aria-current="page" to="/profile">
                <div className="svgwarp">
                  <ProfileSvg />
                </div>
                Profile
              </NavLink>
            </li>
            <li className={`${path}` === "/replymessage" ? "active" : ""}>
              <NavLink aria-current="page" to="/replymessage">
                <div className="svgwarp">
                  <ReplySvg />
                </div>
                Reply Meassage
              </NavLink>
            </li>
            <li className={`${path}` === "/automessage" ? "active" : ""}>
              <NavLink aria-current="page" to="/automessage">
                <div className="svgwarp">
                  <AutoSvg />
                </div>
                Auto Message
              </NavLink>
            </li>
            <li className={`${path}` === "/moreapps" ? "active" : ""}>
              <NavLink aria-current="page" to="/moreapps">
                <div className="svgwarp">
                  <MoreappsSvg />
                </div>
                More Apps
              </NavLink>
            </li>
            <li className={`${path}` === "/fakevideo" ? "active" : ""}>
              <NavLink aria-current="page" to="/fakevideo">
                <div className="svgwarp">
                  <FakeVideoSvg />
                </div>
                Fake Video Data
              </NavLink>
            </li>
            <li className={`${path}` === "/realvideo" ? "active" : ""}>
              <NavLink aria-current="page" to="/realvideo">
                <div className="svgwarp">
                  <FakeVideoSvg />
                </div>
                Real Video Data
              </NavLink>
            </li>
            {user && user.is_superadmin === 1 ? (
              <>
                <li className={`${path}` === "/settings" ? "active" : ""}>
                  <NavLink aria-current="page" to="/settings">
                    <div className="svgwarp">
                      <SettingSvg />
                    </div>
                    Settings
                  </NavLink>
                </li>
              </>
            ) : null}
          </ul>
        </nav>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Sub Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Signup />
        </Modal.Body>
      </Modal>
    </>
  );
}
