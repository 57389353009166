import React, { useEffect, useState } from "react";
import TableDisplay from "./TableDisplay";
import { GetApi } from "./Helper/Axios";

export default function Users() {
  const [title, setTitle] = useState([
    "id",
    "fullname",
    "email",
    "gender",
    "age",
    "device_id",
    "coins",
    "country",
    "region",
    "city",
    "isp",
    "org",
    "data_list_show_type",
    "packagename",
    "createdAt",
    "is_active",    
  ]);

  return (
    <div>
      <TableDisplay
        category="Users"
        tableurl="admin-get-all-user"
        title={title}
        deleteurl=""
        addurl=""
        // formConfig={formConfig}
        // initialValues={initialValues}
        updateurl=""
        updatteurl=""
        serachCategory="name"
        statusurl="admin-status-user"
        axiosHeader={false}
      />
    </div>
  );
}
