import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ChangePassword from "../ChangePassword";
import AvatarImage from "../../images/ava-header.png";
import { ReactComponent as LogoutSvg } from "../../images/logout.svg";
import { ReactComponent as ChangePasswordSvg } from "../../images/lock.svg";
import { useSelector } from "react-redux";
import { ReactComponent as UserSvg } from "../../images/user.svg";
export default function Navbar() {
  const user = useSelector((state) => state.auth.user);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="navbar d-flex justify-content-between">
      <div>
        <h6 className="mb-0">Welcome, {user && user?.name}</h6>{" "}
      </div>
      <Dropdown>
        <Dropdown.Toggle variant="Secondary" id="dropdown-basic">
          <div className="profile-image-wrap">
            <img src={AvatarImage} alt="profile avatar" />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item>
            <Button className="changebtn d-flex">
              <div className="svgwarp drop mr-3">
                <UserSvg />
              </div>
              {user?.email}
            </Button>
          </Dropdown.Item>
          <Dropdown.Item>
            <Button className="changebtn d-flex" onClick={handleShow}>
              <div className="svgwarp drop mr-3">
                <ChangePasswordSvg />
              </div>
              Change Password
            </Button>
          </Dropdown.Item>
          <Dropdown.Item>
            <Button
              className="changebtn d-flex"
              onClick={() => {
                localStorage.removeItem("token");
                window.location.href = "/";
              }}
            >
              <div className="svgwarp drop mr-3">
                <LogoutSvg />
              </div>
              Logout
            </Button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangePassword />
        </Modal.Body>
      </Modal>
    </div>
  );
}
