import React, { useContext, useState } from "react";
import { GetApi, PostApi } from "./Helper/Axios";
import { useFormik } from "formik";
import MyContext from "./MyContext";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
const animatedComponents = makeAnimated();

export default function ProfileForm() {
  const context = useContext(MyContext);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imageArray, setImageArray] = useState(
    context.data.profileimages && context.data.profileimages
  );
  const [success, setSuccess] = useState("");
  const [visibleAlert, setVisibleAlert] = useState(false);
  let [resa, setresa] = useState([]);
  let [resauto, setresauto] = useState([]);

  const id = context.data && context.data.id;
  const handleVisible = () => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 2000);
  };
  let [uploadMultImg, setUploadMultImg] = useState();
  const successNotify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const errorNotify = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const formik = useFormik({
    initialValues: context.data,
    validationSchema: context.formConfig.validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsLoading(true);
      const formData = new FormData();
      let language = [];
      langResult &&
        langResult.map((res) => {
          language.push(res.value);
        });
      if (uploadMultImg === undefined) {
        for (let i = 0; i < context.data.profileimages.length; i++) {
          formData.append("images", context.data.profileimages[i]);
        }
      } else {
        for (let i = 0; i < uploadMultImg.length; i++) {
          formData.append("images", uploadMultImg[i]);
        }
      }
      filteredArray &&
        filteredArray.map((res) => {
          resa.push(res.value);
        });
      filteredArrayAuto &&
        filteredArrayAuto.map((res) => {
          resauto.push(res.value);
        });
      autoResult &&
        autoResult.map((res) => {
          resauto.push(res.value);
        });
      formData.append("reply_message_id", resa.toString());
      formData.append("fullname", values.fullname);
      formData.append("gender", values.gender);
      formData.append("age", values.age);
      formData.append("state", values.state);
      formData.append("notes", values.notes);
      formData.append("intereststag", values.intereststag);
      formData.append("language", language.toString());
      formData.append("auto_message_id", resauto.toString());
      formData.append("status", values.status);
      formData.append("region", values.region);
      formData.append("data_list_show_type", values.data_list_show_type);
      formData.append("coins", values.coins);
      formData.append("package_name", values.package_name);
      let uurl = context.isupdate ? `${context.updatteurl}/${id}` : context.url;
      await PostApi(uurl, formData, context.axiosHeader, context.token)
        .then((res) => {
          if (res.status === 200) {
            successNotify(res.data.message);
            handleVisible();
            formik.resetForm();
            context.fetchData();
            context.handleClose();
            setIsLoading(false);
          }
        })
        .catch((err) => console.log("Fetching error while updating data", err));
    },
  });
  let token = localStorage.getItem("token");
  const [replyMessage, setReplyMeassage] = useState([
    ...(context?.replyMessage || 0),
  ]); // context?.replyMessage

  const [replyResult, setReplyResult] = useState([]);
  const [autoMessage, setAutoMeassage] = useState([
    ...(context?.autoMessage || 0),
  ]); // context?.autoMessage
  const [autoResult, setAutoResult] = useState();
  const [filteredArray, setFilteredArray] = useState([
    ...context?.filteredData,
  ]);

  context?.filteredData?.map((res) => {
    replyResult?.push(res.value);
  });

  const [filteredArrayAuto, setFilteredArrayAuto] = useState([
    ...context?.filteredDataauto,
  ]);

  context?.filteredDataauto?.map((res) => {
    autoResult?.push(res.value);
  });

  const [langResult, setLangResult] = useState([...context?.languageoptions]);
  const [options, setOptions] = useState([...context?.options]);
  const [autoOptions, setAutoOptions] = useState([...context?.autoOption]);

  const handleDelete = async (file, status) => {
    let array = selectedFiles && [...selectedFiles];
    if (status === "selectedFiles" && file >= 0 && file < array.length) {
      array && array.splice(file, 1);
      setSelectedFiles(array);
    } else {
      await GetApi(`admin-image-delete?id=${id}&file=${file}`, context.token)
        .then((res) => {
          if ((res.status = 200)) {
            setImageArray(imageArray.filter((item) => item.image !== file));
          }
        })
        .catch((err) => console.log("Fetching error while updating data", err));
    }
  };
  const handleImageUpload = (e) => {
    let uploadFiles = e.target.files;
    let newUploadImg = [...selectedFiles];
    for (let i = 0; i < uploadFiles.length; i++) {
      newUploadImg.push(uploadFiles[i]);
    }
    setSelectedFiles(newUploadImg);
    setUploadMultImg(e.target.files);
  };
  return (
    <form
      onSubmit={formik.handleSubmit}
      className={
        context.title === "Profile"
          ? "profileform d-flex justify-content-between"
          : ""
      }
    >
      {context.formConfig.fields.map((field) => (
        <div
          key={field.name}
          className={
            field.name === "reply_message_id" ||
            field.name === "auto_message_id" ||
            field.name === "images" ||
            field.name === "package_name"
              ? "full"
              : ""
          }
        >
          <label className="popup__label">{field.label}</label>
          {/* Render different input types based on field.type */}
          {field.type === "selectlang" ? (
            // Render select dropdown
            <div className="selectWrap mb-3">
              <Select
                options={field.options}
                closeMenuOnSelect={false}
                defaultValue={[...langResult]}
                components={animatedComponents}
                onChange={(e) => setLangResult(e)}
                isMulti
                className={"my-select "}
              />
            </div>
          ) : field.type === "autoselect" ? (
            // Render select dropdown
            <div className="selectWrap mb-3">
              <Select
                options={autoOptions}
                closeMenuOnSelect={false}
                defaultValue={[...filteredArrayAuto]}
                components={animatedComponents}
                onChange={(e) => setFilteredArrayAuto(e)}
                isMulti
                className={"my-select "}
              />
            </div>
          ) : field.type === "replyselect" ? (
            // Render select dropdown
            <div className="selectWrap mb-3">
              <Select
                options={options}
                defaultValue={[...filteredArray]}
                closeMenuOnSelect={false}
                components={animatedComponents}
                onChange={(e) => setFilteredArray(e)}
                isMulti
                className={"my-select "}
              />
            </div>
          ) : field.type === "regionselect" ? (
            // Render select dropdown
            <div className="selectWrap custon position-relative mb-3">
              <select
                name={field.name}
                className={
                  "my-select " + (formik.errors[field.name] ? "errorinput" : "")
                }
                onChange={formik.handleChange}
              >
                <option value="">Select {field.label}</option>
                {field.options &&
                  field.options.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      selected={option.id === formik.values[field.name]}
                    >
                      {option.country}
                    </option>
                  ))}
              </select>
            </div>
          ) : field.type === "select" ? (
            // Render select dropdown
            <div className="selectWrap custon position-relative mb-3">
              <select
                name={field.name}
                onChange={formik.handleChange}
                className={
                  "my-select " + (formik.errors[field.name] ? "errorinput" : "")
                }
              >
                <option value="">Select {field.label}</option>
                {field.options &&
                  field.options.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      selected={option.id === formik.values[field.name]}
                    >
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>
          ) : field.type === "files" ? (
            <div className="imageclass mb-3 d-flex">
              <div className="imageWrapcustum marginright">
                <input
                  type="file"
                  multiple
                  name={field.name}
                  // onChange={(e) => {
                  //   setUploadMultImg(e.target.files);
                  //   setSelectedFiles(Array.from(e.target.files));
                  // }}
                  onChange={
                    // setUploadMultImg(e.target.files);
                    // setSelectedFiles(Array.from(e.target.files));
                    handleImageUpload
                  }
                />
              </div>
              <div className="error">
                {formik.touched[field.name] && formik.errors[field.name] ? (
                  <p> {formik.errors[field.name]}</p>
                ) : null}
              </div>
              <div className="image-preview-container d-flex">
                {imageArray &&
                  imageArray.map((file, index) => {
                    if (file.image.endsWith(".mp4")) {
                      return (
                        <div className="btnrmvwrap">
                          <div className="imageWrap profile mt-2 mb-2">
                            <video
                              key={index}
                              src={`${process.env.REACT_APP_UPLOADS_URL}profiles/${file.image}`}
                              className="image-preview"
                              controls
                            />
                          </div>
                          <button
                            className="remove-btn"
                            type="button"
                            onClick={() =>
                              handleDelete(file.image, "imageArray")
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                            >
                              <path
                                fill="currentcolor"
                                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                              />
                            </svg>
                          </button>
                        </div>
                      );
                    } else {
                      return (
                        <div className="btnrmvwrap">
                          <div className="imageWrap profile mt-2 mb-2">
                            <img
                              key={index}
                              src={`${process.env.REACT_APP_UPLOADS_URL}profiles/${file.image}`}
                              alt={`Selected file ${file}`}
                              className="image-preview"
                            />
                          </div>
                          <button
                            className="remove-btn"
                            type="button"
                            onClick={() =>
                              handleDelete(file.image, "imageArray")
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                            >
                              <path
                                fill="currentcolor"
                                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                              />
                            </svg>
                          </button>
                        </div>
                      );
                    }
                  })}
                {selectedFiles &&
                  selectedFiles.map((file, index) => {
                    if (file.type.startsWith("video/")) {
                      return (
                        <div className="btnrmvwrap">
                          <div className="imageWrap profile mt-2 mb-2">
                            <video
                              key={index}
                              src={URL.createObjectURL(file)}
                              controls
                            />
                          </div>
                          <button
                            className="remove-btn"
                            type="button"
                            onClick={() => handleDelete(index, "selectedFiles")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                            >
                              <path
                                fill="currentcolor"
                                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                              />
                            </svg>
                          </button>
                        </div>
                      );
                    } else {
                      return (
                        <div className="btnrmvwrap">
                          <div className="imageWrap profile mt-2 mb-2">
                            <img
                              key={index}
                              src={URL.createObjectURL(file)}
                              alt={`Selected file ${index + 1}`}
                              className="image-preview"
                            />
                          </div>
                          <button
                            className="remove-btn"
                            type="button"
                            onClick={() => handleDelete(index, "selectedFiles")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 384 512"
                            >
                              <path
                                fill="currentcolor"
                                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                              />
                            </svg>
                          </button>
                        </div>
                      );
                    }
                  })}
              </div>
              {/* {file && !context.isupdate ? <div className='imageWrap mt-2 mb-2'><img src={file} alt="image12" /></div> : null}
                            {formik.values.flag && context.isupdate ? <div className='imageWrap mt-2 mb-2'><img className='img-thumbnail' src={`${process.env.REACT_APP_UPLOADS_URL}flags/${formik.values.flag}`} alt={formik.values.flag} /></div> : null} */}
            </div>
          ) : field.type === "switch" ? (
            <label className="switch">
              <input
                type="checkbox"
                name={field.name}
                checked={formik.values[field.name] || 0}
                // value={formik.values[field.name]}
                onChange={(e) => {
                  formik.setFieldValue(
                    field.name,
                    formik.values[field.name] === 1 ? 0 : 1
                  );
                }}
              />
              <span className="slider round"></span>
            </label>
          ) : (
            // Render other input types (e.g., text, number, email)
            <div className="form-input">
              <input
                type={field.type}
                placeholder={field.placeholder}
                name={field.name}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
                className={
                  "popup__input " +
                  (formik.errors[field.name] ? "errorinput" : "")
                }
              />
              <div className="error">
                {formik.touched[field.name] && formik.errors[field.name] ? (
                  <p> {formik.errors[field.name]}</p>
                ) : null}
              </div>
            </div>
          )}
        </div>
      ))}
      {success && visibleAlert ? (
        <div className="alert alert-success" role="alert">
          {success}
        </div>
      ) : null}
      <div className="clearfix"></div>
      <div className="d-flex justify-content-center ">
        <button
          className="mybtn d-flex justify-content-center"
          type="submit"
          // disabled={isLoading}
        >
          <span className={"loader-1 marginright " + (isLoading ? "show" : "")}>
            {" "}
          </span>
          {context.isupdate
            ? `update ${context.title}`
            : isLoading === true
            ? "Loading"
            : `add ${context.title}`}
        </button>
      </div>
    </form>
  );
}

// import React, { useContext, useState } from "react";
// import { GetApi, PostApi } from "./Helper/Axios";
// import { useFormik } from "formik";
// import MyContext from "./MyContext";
// import Select from "react-select";
// import makeAnimated from "react-select/animated";
// import { toast } from "react-toastify";
// const animatedComponents = makeAnimated();

// export default function ProfileForm() {
//   const context = useContext(MyContext);
//   const [isLoading, setIsLoading] = useState(false);
//   const [selectedFiles, setSelectedFiles] = useState([]);
//   const [imageArray, setImageArray] = useState(
//     context.data.profileimages && context.data.profileimages
//   );
//   const [success, setSuccess] = useState("");
//   const [visibleAlert, setVisibleAlert] = useState(false);
//   let [resa, setresa] = useState([]);
//   let [resauto, setresauto] = useState([]);

//   const id = context.data && context.data.id;
//   const handleVisible = () => {
//     setVisibleAlert(true);
//     setTimeout(() => {
//       setVisibleAlert(false);
//     }, 2000);
//   };
//   let [uploadMultImg, setUploadMultImg] = useState();
//   const successNotify = (message) =>
//     toast.success(message, {
//       position: "top-right",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "light",
//     });
//   const errorNotify = (message) =>
//     toast.error(message, {
//       position: "top-right",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "light",
//     });
//   const formik = useFormik({
//     initialValues: context.data,
//     validationSchema: context.formConfig.validationSchema,
//     enableReinitialize: true,
//     onSubmit: async (values) => {
//       setIsLoading(true);
//       const formData = new FormData();
//       let language = [];
//       langResult &&
//         langResult.map((res) => {
//           language.push(res.value);
//         });
//       if (uploadMultImg === undefined) {
//         for (let i = 0; i < context.data.profileimages.length; i++) {
//           formData.append("images", context.data.profileimages[i]);
//         }
//       } else {
//         for (let i = 0; i < uploadMultImg.length; i++) {
//           formData.append("images", uploadMultImg[i]);
//         }
//       }
//       filteredArray &&
//         filteredArray.map((res) => {
//           resa.push(res.value);
//         });
//       filteredArrayAuto &&
//         filteredArrayAuto.map((res) => {
//           resauto.push(res.value);
//         });
//       autoResult &&
//         autoResult.map((res) => {
//           resauto.push(res.value);
//         });
//       formData.append("reply_message_id", resa.toString());
//       formData.append("fullname", values.fullname);
//       formData.append("gender", values.gender);
//       formData.append("age", values.age);
//       formData.append("state", values.state);
//       formData.append("notes", values.notes);
//       formData.append("intereststag", values.intereststag);
//       formData.append("language", language.toString());
//       formData.append("auto_message_id", resauto.toString());
//       formData.append("status", values.status);
//       formData.append("region", values.region);
//       formData.append("data_list_show_type", values.data_list_show_type);
//       formData.append("coins", values.coins);
//       let uurl = context.isupdate ? `${context.updatteurl}/${id}` : context.url;
//       await PostApi(uurl, formData, context.axiosHeader, context.token)
//         .then((res) => {
//           if (res.status === 200) {
//             successNotify(res.data.message);
//             handleVisible();
//             formik.resetForm();
//             context.fetchData();
//             context.handleClose();
//             setIsLoading(false);
//           }
//         })
//         .catch((err) => console.log("Fetching error while updating data", err));
//     },
//   });
//   let token = localStorage.getItem("token");
//   const [replyMessage, setReplyMeassage] = useState([
//     ...(context?.replyMessage || 0),
//   ]); // context?.replyMessage

//   const [replyResult, setReplyResult] = useState([]);
//   const [autoMessage, setAutoMeassage] = useState([
//     ...(context?.autoMessage || 0),
//   ]); // context?.autoMessage
//   const [autoResult, setAutoResult] = useState();
//   const [filteredArray, setFilteredArray] = useState([
//     ...context?.filteredData,
//   ]);

//   context?.filteredData?.map((res) => {
//     replyResult?.push(res.value);
//   });

//   const [filteredArrayAuto, setFilteredArrayAuto] = useState([
//     ...context?.filteredDataauto,
//   ]);

//   context?.filteredDataauto?.map((res) => {
//     autoResult?.push(res.value);
//   });

//   const [langResult, setLangResult] = useState([...context?.languageoptions]);
//   const [options, setOptions] = useState([...context?.options]);
//   const [autoOptions, setAutoOptions] = useState([...context?.autoOption]);

//   const handleDelete = async (file, status) => {
//     let array = selectedFiles && [...selectedFiles];
//     if (status === "selectedFiles" && file >= 0 && file < array.length) {
//       array && array.splice(file, 1);
//       setSelectedFiles(array);
//     } else {
//       await GetApi(`admin-image-delete?id=${id}&file=${file}`, context.token)
//         .then((res) => {
//           if ((res.status = 200)) {
//             setImageArray(imageArray.filter((item) => item.image !== file));
//           }
//         })
//         .catch((err) => console.log("Fetching error while updating data", err));
//     }
//   };
//   const handleImageUpload = (e) => {
//     let uploadFiles = e.target.files;
//     let newUploadImg = [...selectedFiles];
//     for (let i = 0; i < uploadFiles.length; i++) {
//       newUploadImg.push(uploadFiles[i]);
//     }
//     setSelectedFiles(newUploadImg);
//     setUploadMultImg(e.target.files);
//   };
//   return (
//     <form
//       onSubmit={formik.handleSubmit}
//       className={
//         context.title === "Profile"
//           ? "profileform d-flex justify-content-between"
//           : ""
//       }
//     >
//       {context.formConfig.fields.map((field) => (
//         <div
//           key={field.name}
//           className={
//             field.name === "reply_message_id" ||
//             field.name === "auto_message_id" ||
//             field.name === "images" ||
//             field.name === "package_name"
//               ? "full"
//               : ""
//           }
//         >
//           <label className="popup__label">{field.label}</label>
//           {/* Render different input types based on field.type */}
//           {field.type === "selectlang" ? (
//             // Render select dropdown
//             <div className="selectWrap mb-3">
//               <Select
//                 options={field.options}
//                 closeMenuOnSelect={false}
//                 defaultValue={[...langResult]}
//                 components={animatedComponents}
//                 onChange={(e) => setLangResult(e)}
//                 isMulti
//                 className={"my-select "}
//               />
//             </div>
//           ) : field.type === "autoselect" ? (
//             // Render select dropdown
//             <div className="selectWrap mb-3">
//               <Select
//                 options={autoOptions}
//                 closeMenuOnSelect={false}
//                 defaultValue={[...filteredArrayAuto]}
//                 components={animatedComponents}
//                 onChange={(e) => setFilteredArrayAuto(e)}
//                 isMulti
//                 className={"my-select "}
//               />
//             </div>
//           ) : field.type === "replyselect" ? (
//             // Render select dropdown
//             <div className="selectWrap mb-3">
//               <Select
//                 options={options}
//                 defaultValue={[...filteredArray]}
//                 closeMenuOnSelect={false}
//                 components={animatedComponents}
//                 onChange={(e) => setFilteredArray(e)}
//                 isMulti
//                 className={"my-select "}
//               />
//             </div>
//           ) : field.type === "regionselect" ? (
//             // Render select dropdown
//             <div className="selectWrap custon position-relative mb-3">
//               <select
//                 name={field.name}
//                 className={
//                   "my-select " + (formik.errors[field.name] ? "errorinput" : "")
//                 }
//                 onChange={formik.handleChange}
//               >
//                 <option value="">Select {field.label}</option>
//                 {field.options &&
//                   field.options.map((option) => (
//                     <option
//                       key={option.id}
//                       value={option.id}
//                       selected={option.id === formik.values[field.name]}
//                     >
//                       {option.country}
//                     </option>
//                   ))}
//               </select>
//             </div>
//           ) : field.type === "select" ? (
//             // Render select dropdown
//             <div className="selectWrap custon position-relative mb-3">
//               <select
//                 name={field.name}
//                 onChange={formik.handleChange}
//                 className={
//                   "my-select " + (formik.errors[field.name] ? "errorinput" : "")
//                 }
//               >
//                 <option value="">Select {field.label}</option>
//                 {field.options &&
//                   field.options.map((option) => (
//                     <option
//                       key={option.id}
//                       value={option.id}
//                       selected={option.id === formik.values[field.name]}
//                     >
//                       {option.name}
//                     </option>
//                   ))}
//               </select>
//             </div>
//           ) : field.type === "files" ? (
//             <div className="imageclass mb-3 d-flex">
//               <div className="imageWrapcustum marginright">
//                 <input
//                   type="file"
//                   multiple
//                   name={field.name}
//                   // onChange={(e) => {
//                   //   setUploadMultImg(e.target.files);
//                   //   setSelectedFiles(Array.from(e.target.files));
//                   // }}
//                   onChange={
//                     // setUploadMultImg(e.target.files);
//                     // setSelectedFiles(Array.from(e.target.files));
//                     handleImageUpload
//                   }
//                 />
//               </div>
//               <div className="error">
//                 {formik.touched[field.name] && formik.errors[field.name] ? (
//                   <p> {formik.errors[field.name]}</p>
//                 ) : null}
//               </div>
//               <div className="image-preview-container d-flex">
//                 {imageArray &&
//                   imageArray.map((file, index) => {
//                     if (file.image.endsWith(".mp4")) {
//                       return (
//                         <div className="btnrmvwrap">
//                           <div className="imageWrap profile mt-2 mb-2">
//                             <video
//                               key={index}
//                               src={`${process.env.REACT_APP_UPLOADS_URL}profiles/${file.image}`}
//                               className="image-preview"
//                               controls
//                             />
//                           </div>
//                           <button
//                             className="remove-btn"
//                             type="button"
//                             onClick={() =>
//                               handleDelete(file.image, "imageArray")
//                             }
//                           >
//                             <svg
//                               xmlns="http://www.w3.org/2000/svg"
//                               viewBox="0 0 384 512"
//                             >
//                               <path
//                                 fill="currentcolor"
//                                 d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
//                               />
//                             </svg>
//                           </button>
//                         </div>
//                       );
//                     } else {
//                       return (
//                         <div className="btnrmvwrap">
//                           <div className="imageWrap profile mt-2 mb-2">
//                             <img
//                               key={index}
//                               src={`${process.env.REACT_APP_UPLOADS_URL}profiles/${file.image}`}
//                               alt={`Selected file ${file}`}
//                               className="image-preview"
//                             />
//                           </div>
//                           <button
//                             className="remove-btn"
//                             type="button"
//                             onClick={() =>
//                               handleDelete(file.image, "imageArray")
//                             }
//                           >
//                             <svg
//                               xmlns="http://www.w3.org/2000/svg"
//                               viewBox="0 0 384 512"
//                             >
//                               <path
//                                 fill="currentcolor"
//                                 d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
//                               />
//                             </svg>
//                           </button>
//                         </div>
//                       );
//                     }
//                   })}
//                 {selectedFiles &&
//                   selectedFiles.map((file, index) => {
//                     if (file.type.startsWith("video/")) {
//                       return (
//                         <div className="btnrmvwrap">
//                           <div className="imageWrap profile mt-2 mb-2">
//                             <video
//                               key={index}
//                               src={URL.createObjectURL(file)}
//                               controls
//                             />
//                           </div>
//                           <button
//                             className="remove-btn"
//                             type="button"
//                             onClick={() => handleDelete(index, "selectedFiles")}
//                           >
//                             <svg
//                               xmlns="http://www.w3.org/2000/svg"
//                               viewBox="0 0 384 512"
//                             >
//                               <path
//                                 fill="currentcolor"
//                                 d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
//                               />
//                             </svg>
//                           </button>
//                         </div>
//                       );
//                     } else {
//                       return (
//                         <div className="btnrmvwrap">
//                           <div className="imageWrap profile mt-2 mb-2">
//                             <img
//                               key={index}
//                               src={URL.createObjectURL(file)}
//                               alt={`Selected file ${index + 1}`}
//                               className="image-preview"
//                             />
//                           </div>
//                           <button
//                             className="remove-btn"
//                             type="button"
//                             onClick={() => handleDelete(index, "selectedFiles")}
//                           >
//                             <svg
//                               xmlns="http://www.w3.org/2000/svg"
//                               viewBox="0 0 384 512"
//                             >
//                               <path
//                                 fill="currentcolor"
//                                 d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
//                               />
//                             </svg>
//                           </button>
//                         </div>
//                       );
//                     }
//                   })}
//               </div>
//               {/* {file && !context.isupdate ? <div className='imageWrap mt-2 mb-2'><img src={file} alt="image12" /></div> : null}
//                             {formik.values.flag && context.isupdate ? <div className='imageWrap mt-2 mb-2'><img className='img-thumbnail' src={`${process.env.REACT_APP_UPLOADS_URL}flags/${formik.values.flag}`} alt={formik.values.flag} /></div> : null} */}
//             </div>
//           ) : field.type === "switch" ? (
//             <label className="switch">
//               <input
//                 type="checkbox"
//                 name={field.name}
//                 checked={formik.values[field.name] || 0}
//                 // value={formik.values[field.name]}
//                 onChange={(e) => {
//                   formik.setFieldValue(
//                     field.name,
//                     formik.values[field.name] === 1 ? 0 : 1
//                   );
//                 }}
//               />
//               <span className="slider round"></span>
//             </label>
//           ) : (
//             // Render other input types (e.g., text, number, email)
//             <div className="form-input">
//               <input
//                 type={field.type}
//                 placeholder={field.placeholder}
//                 name={field.name}
//                 value={formik.values[field.name]}
//                 onChange={formik.handleChange}
//                 className={
//                   "popup__input " +
//                   (formik.errors[field.name] ? "errorinput" : "")
//                 }
//               />
//               <div className="error">
//                 {formik.touched[field.name] && formik.errors[field.name] ? (
//                   <p> {formik.errors[field.name]}</p>
//                 ) : null}
//               </div>
//             </div>
//           )}
//         </div>
//       ))}
//       {success && visibleAlert ? (
//         <div className="alert alert-success" role="alert">
//           {success}
//         </div>
//       ) : null}
//       <div className="clearfix"></div>
//       <div className="d-flex justify-content-center ">
//         <button
//           className="mybtn d-flex justify-content-center"
//           type="submit"
//           // disabled={isLoading}
//         >
//           <span className={"loader-1 marginright " + (isLoading ? "show" : "")}>
//             {" "}
//           </span>
//           {context.isupdate
//             ? `update ${context.title}`
//             : isLoading === true
//             ? "Loading"
//             : `add ${context.title}`}
//         </button>
//       </div>
//     </form>
//   );
// }
