import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../redux/authSlice";
import Lottie from "lottie-react";
import loginImage from "../images/Animation - 1714535446652.json";
import { FiEye, FiEyeOff } from "react-icons/fi";
import logo from "../images/Frame 51871.png";
function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Initialize useDispatch hook
  let token = localStorage.getItem("token");
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState();
  const [emailStatus, setEmailStatus] = useState(false);
  const [formdata, setFormdata] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  useEffect(() => {
    let timer;
    if (error) {
      timer = setTimeout(() => {
        setError("");
      }, 3000); // 3000 milliseconds = 3 seconds
    }

    return () => {
      clearTimeout(timer);
    };
  }, [error]);
  const formik = useFormik({
    initialValues: formdata,
    validationSchema: Yup.object({
      email: Yup.string().required("email Required"),
      password: Yup.string().required("password Required"),
    }),
    onSubmit: async (values) => {
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}check-admin`, values)
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("token", response.data.data.token);
            dispatch(loginUser(response.data.data.user));
            formik.resetForm();
            navigate("/user");
          }
        })
        .catch((err) => {
          setError(err.response.data.message);
        });
    },
  });
  const handleForgetPassword = () => {
    if (
      email !== "" &&
      email !== undefined &&
      email !== null &&
      emailStatus === "Success"
    ) {
      navigate(`/forgotpassword?email=${encodeURIComponent(email)}`);
    } else if (emailStatus !== "Success") {
      setError("Email Address does not exist.");
    } else {
      setError("enter email");
    }
    // Navigate to forget password page with email as a query parameter
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const checkEmail = async (e) => {
    setEmailStatus(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}admin-checkemail?email=${e}`)
      .then((response) => {
        if (response.status === 200) {
          setEmailStatus("Success");
        }
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };
  return (
    <div className="container-login">
      <section
        className="login-section d-flex  
        align-items-center  
        justify-content-between  
        min-vh-100"
      >
        <div className="loginformWrap col-3">
          <div className="loginlogowrap d-flex justify-content-center  align-items-center mb-5">
            <div className="logo-wrap1 marginright">
              <img src={logo} alt="logo wrap" />
            </div>
            <div className="logo-content">
              <h4>Video Call</h4>
            </div>
          </div>
          <div className="login-title">
            <h4>Login</h4>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="login-feild-wrap">
              <div className="myinput">
                <input
                  type="text"
                  placeholder="Username"
                  name="email"
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.setFieldValue("email", e.target.value);
                    setEmail(e.target.value);
                    checkEmail(e.target.value);
                  }}
                  className={formik.errors.email ? "errorinput" : ""}
                />
                {/* className={`mytable ${(category === "Users" ? 'users' : '')}`} */}
                {emailStatus && email !== "" ? (
                  <div
                    className={`svgwrap ${
                      emailStatus === "Success" ? "green" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentcolor"
                        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                      />
                    </svg>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="error">
              {formik.touched.email && formik.errors.email ? (
                <p>{formik.errors.email}</p>
              ) : null}
            </div>
            <div className="login-feild-wrap">
              <div className="myinput">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  className={formik.errors.password ? "errorinput" : ""}
                />
                <span className="eye" onClick={togglePasswordVisibility}>
                  {showPassword ? <FiEyeOff /> : <FiEye />}
                </span>
              </div>
              {/* <div className='d-flex flex-row-reverse mb-4 colr'>
                            <button type='button' className='forget-btn' onClick={handleForgetPassword}>Forget Password</button>
                        </div> */}
            </div>
            <div className="error">
              {formik.touched.password && formik.errors.password ? (
                <p>{formik.errors.password}</p>
              ) : null}
            </div>
            <div className="button-login">
              <button type="submit">Login</button>
            </div>
            {/* <div className='d-flex flex-row-reverse colr'>
                        <Link to='/signup'>Create Account</Link>
                    </div> */}
            <div className="error mt-2">
              {error && error ? <p>{error}</p> : null}
            </div>
          </form>
        </div>
        <div className="logincontainerwrap col-8 position-relative">
          <Lottie animationData={loginImage} loop={true} />
        </div>
      </section>
    </div>
  );
}

export default Login;
