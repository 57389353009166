import axios from "axios";
import { useNavigate } from "react-router-dom";

export const GetApi = async (url, token = "") => {
  let urll = process.env.REACT_APP_BASE_URL + url;
  let response;
  try {
    if (token !== "") {
      response = await axios
        .get(urll, {
          headers: {
            "x-access-token": token,
          },
        })
        .then()
        .catch((err) => {
          if (err.response.status === 401) {
            window.location.href = "/";
          }
          throw err;
        });
    } else {
      response = await axios
        .get(urll)
        .then()
        .catch((err) => {
          if (err.response.status === 401) {
            window.location.href = "/";
          }
        });
    }

    return response;
  } catch (error) {
    console.error("Request failed:", error);
    if (error.response.status === 401) {
      window.location.href = "/";
    }
    throw error;
  }
};

export const PostApi = async (url, data, headers = false, token = "") => {
  let urll = process.env.REACT_APP_BASE_URL + url;

  if (headers === true && token !== "" && token !== undefined) {
    try {
      const response = await axios
        .post(urll, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-access-token": token,
          },
        })
        .then()
        .catch((err) => {
          if (err.response.status === 401) {
            window.location.href = "/";
          }
          throw err;
        });
      return response;
    } catch (error) {
      console.error("Request failed:", error);
      if (error.response.status === 401) {
        window.location.href = "/";
      }
      throw error;
    }
  } else if (headers === true) {
    try {
      const response = await axios
        .post(urll, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          // console.log(res)
        })
        .catch((err) => {
          if (err.response.status === 401) {
            window.location.href = "/";
          }
        });
      return response;
    } catch (error) {
      if (error.response.status === 401) {
        window.location.href = "/";
      }
      throw error;
    }
  } else if (token !== "" && headers === false) {
    try {
      let response = await axios
        .post(urll, data, {
          headers: {
            "x-access-token": token,
          },
        })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            window.location.href = "/";
          } else {
            // throw new Error(err.response.data);
            // response = err.response.data;
            throw err;
          }
        });
      return response;
    } catch (error) {
      if (error.response.status === 401) {
        window.location.href = "/";
      }
      throw error;
    }
  } else {
    try {
      const response = await axios
        .post(urll, data)
        .then()
        .catch((err) => {
          if (err.response.status === 401) {
            window.location.href = "/";
          }
          throw err;
        });
      return response;
    } catch (error) {
      console.error("Request failed:", error);
      if (error.response.status === 401) {
        window.location.href = "/";
      }
      throw error;
    }
  }
};
