import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import axios from "axios";
import { PostApi } from './Helper/Axios';
import { Link, useLocation, useNavigate } from "react-router-dom";
function ForgotPassword() {
    const navigate = useNavigate();
    const [formdata, setFormdata] = useState({
        password: ''
    })
    const [error, setError] = useState('')
    const formik = useFormik({
        initialValues: formdata,
        validationSchema: Yup.object({
            password: Yup.string()
                .required('password Required')
        }),
        onSubmit: async (values) => {
            await axios.post(`${process.env.REACT_APP_BASE_URL}admin-changepassword?email=${email}`, values)
                .then((response) => {

                    if (response.status === 200) {
                        navigate("/");
                    }
                }).catch((err) => {
                    setError(err.response.data.message);
                });

        },
    });
    const [email, setEmail] = useState('');
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const emailFromQuery = searchParams.get('email');
        if (emailFromQuery) {
            setEmail(emailFromQuery);
        }
    }, [location.search]);
    return (
        <section className='login-section d-flex  
        align-items-center  
        justify-content-center  
        min-vh-100'>
            <div className='loginformWrap col-md-3'>

                <div className='login-title'>
                    <h4>Forgot Password</h4> <h6>username :{email}</h6>
                </div>

                <form onSubmit={formik.handleSubmit}>
                    <div className='login-feild-wrap'>
                        <label className='formlable'>Password</label>
                        <div className='myinput'>
                            <input
                                type="text"
                                placeholder="password"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                className={formik.errors.password ? "errorinput" : ''}
                            />
                        </div>
                    </div>
                    <div className='error'>
                        {formik.touched.password && formik.errors.password ? (
                            <p>{formik.errors.password}</p>
                        ) : null}
                    </div>
                    <div className='button-login'>
                        <button type="submit">Enter Password</button>
                    </div>
                    <div className='d-flex flex-row-reverse colr'>
                        <Link to='/'>Login</Link>
                    </div>
                    <div className='error mt-2'>
                        {error && error ? (
                            <p>{error}</p>
                        ) : null}
                    </div>
                </form>
            </div>
        </section>
    );
}

export default ForgotPassword;