import React, { useState } from "react";
import TableDisplay from "./TableDisplay";
import * as Yup from "yup";

export default function CoinPack() {
  const [title, setTitle] = useState([
    "id",
    "coins",
    "price",
    "icon",
    "discount",
    "sku",
    "package_name",
    "status",
  ]);
  const formConfig = {
    fields: [
      {
        name: "coins",
        label: "Coins",
        type: "text",
        placeholder: "enter coins",
      },
      {
        name: "price",
        label: "Price",
        type: "text",
        placeholder: "Enter Price",
      },
      {
        name: "icon",
        label: "Icon",
        type: "file",
      },
      {
        name: "discount",
        label: "Discount (%)",
        type: "text",
      },
      {
        name: "sku",
        label: "SKU",
        type: "text",
      },
      {
        name: "package_name",
        label: "Package Name",
        type: "text",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      coins: Yup.number().required("coin number Required"),
      price: Yup.number().required("price number Required"),
      icon: Yup.string().required("icon Required"),
      discount: Yup.number().required("discount number Required"),
      sku: Yup.string().required("sku Required"),
    }),
  };

  const initialValues = {
    coins: "",
    price: "",
    icon: "",
    discount: "",
    sku: "",
    status: 0,
    package_name: "",
  };
  return (
    <div>
      <TableDisplay
        category="Coin Package"
        tableurl="admin-get-coins-pack"
        title={title}
        deleteurl="admin-delete-coins-pack"
        deleteallurl="admin-all-delete-coins"
        addurl="add-coins-pack"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-edit-coins-pack"
        updatteurl="admin-edit-coins-pack"
        serachCategory="name"
        statusurl="admin-status-coins-pack"
        axiosHeader={true}
      />
    </div>
  );
}
