import React, { useState } from "react";
import TableDisplay from "./TableDisplay";
import * as Yup from "yup";
export default function Payment() {
  const [title, setTitle] = useState([
    "id",
    "getway",
    "coins",
    "user name",
    "user_id",
    "Package Name",
  ]);
  return (
    <div>
      <TableDisplay
        category="Payment"
        tableurl="admin-payment-history"
        title={title}
        deleteurl="admin-delete-country"
        addurl="add-country"
        // formConfig={formConfig}
        // initialValues={initialValues}
        updateurl="get-edit-country"
        updatteurl="admin-edit-country"
        serachCategory="payment"
        statusurl="admin-status-country"
        axiosHeader={true}
      />
    </div>
  );
}
