import React, { useEffect, useState } from "react";
import Sidebar from "./sidebar";
import { Provider } from "react-redux";
import store from "../../redux/store";
import Navbar from "./navbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Index({ children }) {
  const [isChecked, setIsChecked] = useState();  
  useEffect(() => {
    // Function to handle the change in screen size
    const handleResize = () => {
      // Check if the screen width is less than 768px (tablet view)
      if (window.matchMedia("(max-width: 768px)").matches) {
        setIsChecked(false);
      } else {
        setIsChecked(true);
      }
    };

    // Initial check when the component mounts
    handleResize();

    // Add event listener for screen size changes
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  let token = localStorage.getItem("token");
  if (!token || token === null) {
    return (window.location.href = "/");
  }
  // Set the default checked state here

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };
  return (
    <Provider store={store}>
      <div>
        <input
          type="checkbox"
          id="menu"
          defaultChecked={isChecked}
          onChange={handleChange}
        />
        <Sidebar />
        <Navbar />
        <div className="main-section">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          {/* Same as */}
          <ToastContainer />
          {children}
        </div>
      </div>
    </Provider>
  );
}
