import React, { useState } from "react";
import TableDisplay from "./TableDisplay";
import * as Yup from "yup";
export default function Country() {
  const [title, setTitle] = useState([
    "id",
    "country",
    "flag",
    "package_name",
    "status",
  ]);
  const formConfig = {
    fields: [
      {
        name: "country",
        label: "Country",
        type: "text",
        placeholder: "enter country",
      },
      {
        name: "flag",
        label: "Flag",
        type: "file",
      },
      {
        name: "package_name",
        label: "Package Name",
        type: "text",
        placeholder: "enter package name",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      country: Yup.string().required("country name Required"),
      flag: Yup.mixed().required("Please upload an image"),
    }),
  };

  const initialValues = {
    country: "",
    flag: "",
    package_name: "",
    status: 0,
  };
  return (
    <div>
      <TableDisplay
        category="Country"
        tableurl="admin-get-country"
        title={title}
        deleteurl="admin-delete-country"
        deleteallurl="admin-all-delete-country"
        addurl="add-country"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-edit-country"
        updatteurl="admin-edit-country"
        serachCategory="country"
        statusurl="admin-status-country"
        axiosHeader={true}
      />
    </div>
  );
}
