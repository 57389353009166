import React, { useState } from "react";
import TableDisplay from "./TableDisplay";
import * as Yup from "yup";

export default function Replymessage() {
  const title = ["id", "meassage", "status"];
  const formConfig = {
    fields: [
      {
        name: "meassage",
        label: "Message",
        type: "textarea",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      meassage: Yup.string().required("message Required"),
    }),
  };

  const initialValues = {
    meassage: "",
    status: 0,
  };
  return (
    <div>
      <TableDisplay
        category="Reply Message"
        tableurl="admin-get-reply-message"
        title={title}
        deleteurl="admin-delete-reply-message"
        deleteallurl="admin-all-delete-reply-message"
        addurl="admin-add-reply-message"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-edit-reply-message"
        updatteurl="admin-edit-reply-message"
        serachCategory=""
        statusurl="admin-status-reply-message"
        axiosHeader={false}
      />
    </div>
  );
}
