import React, { useContext, useEffect, useMemo, useState } from "react";
import { GetApi, PostApi } from "./Helper/Axios";
import { useFormik } from "formik";
import MyContext from "./MyContext";
import { toast } from "react-toastify";

export default function DynamicForm() {
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(MyContext);
  const [file, setFile] = useState();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [visibleAlert, setVisibleAlert] = useState(false);
  const id = context.data && context.data.id;
  const handleVisible = () => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 2000);
  };
  const successNotify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const errorNotify = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const formik = useFormik({
    initialValues: context.data,
    validationSchema: context.formConfig.validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let msg;
      if (values.meassage !== undefined) {
        msg = values.meassage;
        if (msg[msg.length - 1] === ",") {
          msg = msg.slice(0, -1); // Remove the last character
        }
        values = {
          ...values,
          meassage: msg.split(","),
        };
      }
      if (values.status) {
        values = {
          ...values,
          status: values.status.toString(),
        };
      }
      let uurl = context.isupdate ? `${context.updatteurl}/${id}` : context.url;
      setIsLoading(true);
      await PostApi(uurl, values, context.axiosHeader, context.token)
        .then((res) => {
          if (res.status === 200) {
            successNotify(res.data.message);
            handleVisible();
            formik.resetForm();
            context.fetchData();
            context.handleClose();
          }
        })
        .catch((err) => {
          handleVisible();
          setError(err.response.data);
        });
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={
        context.title === "Profile"
          ? "profileform d-flex justify-content-between"
          : ""
      }
    >
      {context.formConfig.fields.map((field) => (
        <div key={field.name}>
          <label className="popup__label">{field.label}</label>
          {/* Render different input types based on field.type */}
          {field.type === "select" ? (
            // Render select dropdown
            <div className="selectWrap mb-3">
              <select
                name={field.name}
                onChange={formik.handleChange}
                className={
                  "my-select " + (formik.errors[field.name] ? "errorinput" : "")
                }
              >
                <option value="">Select {field.label}</option>
                {field.options &&
                  field.options.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      selected={option.id === formik.values[field.name]}
                    >
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>
          ) : field.type === "file" ? (
            <div className="imageclass mb-3 d-flex">
              <div className="imageWrapcustum marginright">
                <input
                  type="file"
                  name={field.name}
                  onChange={(e) => {
                    formik.setFieldValue(field.name, e.target.files[0]);
                    setFile(URL.createObjectURL(e.target.files[0]));
                  }}
                />
              </div>
              {/* context.data */}

              {file ? (
                <div className="imageWrap profile  mt-2 mb-2">
                  <img src={file} alt="image12" />
                </div>
              ) : context.data.icon ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <img
                    className="img-thumbnail"
                    src={`${process.env.REACT_APP_UPLOADS_URL}icons/${formik.values.icon}`}
                    alt={formik.values.icon}
                  />
                </div>
              ) : context.data.flag ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <img
                    className="img-thumbnail"
                    src={`${process.env.REACT_APP_UPLOADS_URL}flags/${formik.values.flag}`}
                    alt={formik.values.flag}
                  />
                </div>
              ) : context.data.logo ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <img
                    className="img-thumbnail"
                    src={`${process.env.REACT_APP_UPLOADS_URL}logos/${formik.values.logo}`}
                    alt={formik.values.logo}
                  />
                </div>
              ) : context.data.video ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <video
                    className="img-thumbnail"
                    src={`${process.env.REACT_APP_UPLOADS_URL}videos/${formik.values.video}`}
                    alt={formik.values.video}
                    controls
                  />
                </div>
              ) : null}

              {/* {file && !context.isupdate ? <div className='imageWrap mt-2 mb-2'><img src={file} alt="image12" /></div> : null}
                            {formik.values.icon && context.isupdate ? <div className='imageWrap mt-2 mb-2'><img className='img-thumbnail' src={`${process.env.REACT_APP_UPLOADS_URL}icons/${formik.values.icon}`} alt={formik.values.icon} /></div> : null} */}
              {/* {file && !context.isupdate ? <div className='imageWrap mt-2 mb-2'><img src={file} alt="image12" /></div> : null}
                            {formik.values.flag && context.isupdate ? <div className='imageWrap mt-2 mb-2'><img className='img-thumbnail' src={`${process.env.REACT_APP_UPLOADS_URL}flags/${formik.values.flag}`} alt={formik.values.flag} /></div> : null} */}
            </div>
          ) : field.type === "video" ? (
            <div className="imageclass mb-3 d-flex">
              <div className="imageWrapcustum marginright">
                <input
                  type="file"
                  name={field.name}
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={(e) => {
                    formik.setFieldValue(field.name, e.target.files[0]);
                    setFile(e.target.files[0]);
                  }}
                />
              </div>
              {/* context.data */}

              {file ? (
                <div className="imageWrap profile  mt-2 mb-2">
                  <video src={URL.createObjectURL(file)} controls />
                </div>
              ) : context.data.video ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <video
                    className="img-thumbnail"
                    src={`${process.env.REACT_APP_UPLOADS_URL}videos/${formik.values.video}`}
                    alt={formik.values.video}
                    controls
                  />
                </div>
              ) : null}
            </div>
          ) : field.type === "switch" ? (
            <label className="switch">
              <input
                type="checkbox"
                name={field.name}
                checked={formik.values[field.name] || 0}
                // value={formik.values[field.name]}
                onChange={(e) => {
                  formik.setFieldValue(
                    field.name,
                    formik.values[field.name] === 1 ? 0 : 1
                  );
                }}
              />
              <span className="slider round"></span>
            </label>
          ) : field.type === "textarea" ? (
            <div className="form-input">
              <textarea
                name={field.name}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
                className={
                  "popup__input " +
                  (formik.errors[field.name] ? "errorinput" : "")
                }
              />
            </div>
          ) : (
            // Render other input types (e.g., text, number, email)
            <div className="form-input">
              <input
                type={field.type}
                placeholder={field.placeholder}
                name={field.name}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
                className={
                  "popup__input " +
                  (formik.errors[field.name] ? "errorinput" : "")
                }
              />
              <div className="error">
                {formik.touched[field.name] && formik.errors[field.name] ? (
                  <p> {formik.errors[field.name]}</p>
                ) : null}
              </div>
            </div>
          )}
        </div>
      ))}
      {error && visibleAlert ? (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      ) : null}
      {success && visibleAlert ? (
        <div className="alert alert-success" role="alert">
          {success}
        </div>
      ) : null}
      <div className="d-flex justify-content-center ">
        <button
          className="mybtn d-flex justify-content-center"
          type="submit"
          // disabled={isLoading}
        >
          <span className={"loader-1 marginright " + (isLoading ? "show" : "")}>
            {" "}
          </span>
          {context.isupdate
            ? `update ${context.title}`
            : isLoading === true
            ? "Loading"
            : `add ${context.title}`}
        </button>
      </div>
    </form>
  );
}
