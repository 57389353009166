import React, { useState } from "react";
import TableDisplay from "./TableDisplay";
import * as Yup from "yup";
export default function Moreapps() {
  const [title, setTitle] = useState([
    "id",
    "name",
    "logo",
    "rate",
    "link",
    "package_name",
    "status",
  ]);
  const formConfig = {
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text",
        placeholder: "Enter Name",
      },
      {
        name: "logo",
        label: "Logo",
        type: "file",
      },
      {
        name: "rate",
        label: "Rate",
        type: "text",
        placeholder: "Enter Rate",
      },
      {
        name: "link",
        label: "Link",
        type: "text",
        placeholder: "Enter Link",
      },
      {
        name: "package_name",
        label: "Package Name",
        type: "text",
        placeholder: "Enter Package Name",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      name: Yup.string().required("Name Required."),
      logo: Yup.mixed().required("Please upload an image."),
      rate: Yup.number().required("Rate required in numbers."),
      link: Yup.string().required("Link required."),
      package_name: Yup.string().required("Package name required."),
    }),
  };

  const initialValues = {
    name: "",
    logo: "",
    rate: "",
    link: "",
    package_name: "",
    status: 0,
  };
  return (
    <div>
      <TableDisplay
        category="More Apps"
        tableurl="admin-get-moreapps"
        title={title}
        deleteurl="admin-delete-moreapps"
        deleteallurl="admin-all-delete-moreapps"
        addurl="add-moreapps"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-edit-moreapps"
        updatteurl="admin-edit-moreapps"
        statusurl="admin-status-moreapps"
        axiosHeader={true}
      />
    </div>
  );
}
