import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./Components/Login";
import Country from "./Components/Country";
import Users from "./Components/Users";
import CoinPack from "./Components/CoinPack";
import Replymessage from "./Components/replymessage";
import AutoMessage from "./Components/AutoMessage";
import Profiles from "./Components/Profile";
import Payment from "./Components/Payment";
import Layout from "./Components/Layout";
import Signup from "./Components/Signup";
import ForgotPassword from "./Components/ForgotPassword";
import { Provider } from "react-redux";
import store from "./redux/store";
import SettingPermission from "./Components/SettingPermission";
import Moreapps from "./Components/Moreapps";
import FakeVideo from "./Components/FakeVideo";
import RealVideo from "./Components/RealVideo";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Provider store={store}>
        <Login />
      </Provider>
    ),
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/forgotpassword",
    element: <ForgotPassword />,
  },
  {
    path: "/settings",
    element: (
      <Layout>
        <SettingPermission />
      </Layout>
    ),
  },
  {
    path: "/moreapps",
    element: (
      <Layout>
        <Moreapps />
      </Layout>
    ),
  },
  {
    path: "/fakevideo",
    element: (
      <Layout>
        <FakeVideo />
      </Layout>
    ),
  },
  {
    path: "/realvideo",
    element: (
      <Layout>
        <RealVideo />
      </Layout>
    ),
  },
  {
    path: "/country",
    element: (
      <Layout>
        <Country />
      </Layout>
    ),
  },
  {
    path: "/user",
    element: (
      <Layout>
        <Users />
      </Layout>
    ),
  },
  {
    path: "/coin",
    element: (
      <Layout>
        <CoinPack />
      </Layout>
    ),
  },
  {
    path: "/payment",
    element: (
      <Layout>
        <Payment />
      </Layout>
    ),
  },
  {
    path: "/replymessage",
    element: (
      <Layout>
        <Replymessage />
      </Layout>
    ),
  },
  {
    path: "/automessage",
    element: (
      <Layout>
        <AutoMessage />
      </Layout>
    ),
  },
  {
    path: "/profile",
    element: (
      <Layout>
        <Profiles />
      </Layout>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
